
/**
 * @name: 商品管理-商品分类管理
 * @author:
 * @date: 2023-08-04 17:53
 * @description： 商品管理-商品分类管理
 * @update: 2023-08-04 17:53
 */
import {Vue, Component} from "vue-property-decorator"
import type {ICrudOption} from "@/types/m-ui-crud"
import {checkLetter } from "@/constants/validators";
import {IPage} from "@/apis/page";
import {
  productSetDetailApi,
  productSetStatusApi,
  productSortCreateApi,
  productSortModifyApi,
  productSortQueryApi
} from "@/apis/product/sort";
import {IProductSortCreat} from "@/apis/product/sort/types";

@Component({})
export default class sortUser extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: [] = []
  // 表格总数
  tableTotal: number = 0
  // 查询参数
  queryParam: IPage = {
    page: 1,
    limit: 10,
  }
  // 表单参数
  modelForm: Partial<IProductSortCreat> = {
  }
  // crud配置信息
  crudOption: ICrudOption = {
    searchBox: false,
    addTitle: '新增分类',
    editTitle: '编辑分类',
    menu: true,
    editBtn: false,
    delBtn: true,
    dialogWidth: '600px',
    column: [
      {
        label: "分类名称",
        prop: "sortName",
        align: "center",
        span: 24,
        maxlength: 30,
        rules: [
          {required: true, message: "请输入名称", trigger: "blur"}
        ]
      },
      {
        label: "分类字母",
        prop: "sortLetter",
        align: 'center',
        span: 24,
        rules: [
          {required: true, message: '请输入分类字母', trigger: 'blur'},
          {trigger: 'blur', validator: checkLetter}
        ],
      },
      {
        label: "排序",
        prop: "sort",
        align: 'center',
        width: 150,
        span: 24,
        maxlength: 2,
        rules: [
          {required: true, message: '请输入排序', trigger: 'blur'},
          {
            trigger: 'blur', validator: (rule: any, value: any, callback: any) => {
            if (!/^[1-9]\d{0,1}$/.test(value)) {
                callback(new Error('最大为两位正整数'));
              } else {
                callback();
              }
            }
          }
        ],
      },

      {
        label: "状态",
        prop: "status",
        align: 'center',
        slot: true,
        value: 2,
        type: "switch",//状态:1=启用 2=禁用
        dicData: [{label: '禁用', value: 2}, {label: '启用', value: 1}],
        span: 24,
        rules: [
          {required: true, message: '请选择状态', trigger: 'blur'}
        ]
      },
    ]
  }

  /**
   * 新增提交
   */
  submitSave(form: IProductSortCreat, done: any, loading: any) {
    productSortCreateApi(form).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList();
        done();
      }
    }).finally(() => {
      loading();
    })
  }

  /**
   * 修改状态提交
   * @param row
   */
  handleStatus(row: IProductSortCreat) {
    //@ts-ignore
    productSetStatusApi(row.id).then(e => {
      this.$message({
        type: 'success',
        message: '操作成功!'
      });
      this.getList()
    })
  }

  /**
   * 获取列表数据
   */
  getList() {
    productSortQueryApi(this.queryParam).then(e => {
      //@ts-ignore
      this.tableData = e.list;
      this.tableTotal = e.total
    })
  }

  /**
   * 编辑提交
   */
  submitEdit(form: IProductSortCreat, done: any, loading: any) {
    productSortModifyApi(form).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList();
        done();
      }
    }).finally(() => {
      loading();
    })
  }

  openDetail (row: IProductSortCreat, index: number) {
    productSetDetailApi(row.id).then(e => {
      if (e) {
        // @ts-ignore
        this.$refs.crudRef.rowEdit(e, index)
      }
    })
  }

  created() {
    this.getList()
  }
}
